/* eslint-disable max-len */
import React from 'react';

const VennDiagramDefs = () => (
  <svg height="0" width="0">
    <defs>
      <circle id="venn-prod-circle" cx="70" cy="70" r="55" />
      <circle id="venn-eat-circle" cx="122" cy="70" r="55" />
      <circle id="venn-env-circle" cx="96" cy="122" r="55" />
      <rect id="venn-area" width="194" height="194" />
      <clipPath id="venn-prod-clip">
        <use href="#venn-prod-circle" />
      </clipPath>
      <clipPath id="venn-eat-clip">
        <use href="#venn-eat-circle" />
      </clipPath>
      <clipPath id="venn-env-clip">
        <use href="#venn-env-circle" />
      </clipPath>
      <filter id="venn-circle-blur">
        <feDropShadow dx="0" dy="0" stdDeviation="6.0"
          floodColor="#555"/>
        <feComposite operator="out" in2="SourceGraphic"/>
      </filter>
      <symbol id="venn-tick" viewBox="0 0 496.084 441.622">
        <polygon points="238.691 286.725 149.499 203.182 164.45 187.217 235.807 254.068 357.773 91.09 375.286 104.193 238.691 286.725" fill="#fff"/>
      </symbol>
    </defs>
  </svg>
);

export default VennDiagramDefs;
