import React, { Component } from 'react';
import {
  Link,
  withRouter } from 'react-router-dom';
import AuthenticationIcon from '../../util/icons/components/AuthenticationIcon';
import PropTypes from 'prop-types';
import './qr-additional-data.scss';
import InfoIconThick from '../../util/icons/components/InfoIconThick';
import KeyFacts from './KeyFacts';
import { getGBIDomain } from '../../util/site';

class QRAuthentication extends Component {
  renderAuthenticationSection () {
    const documents = Object.values(this.props.cow.documents || {});
    if (documents && documents.length) {
      return (
        <Link to={ `${this.props.match.url}/documents` } >
          <div className="authentication-section">
            <AuthenticationIcon />
            <h5 className="authentication-title">Authenticated</h5>
            <p data-cy="authentication-document-count"
              className="num-documents">
              { documents.length + ' documents' }
            </p>
          </div>
        </Link>
      );
    }
    return null;
  }
  renderAncestryLink () {
    const scrollToAncestry = () => {
      const ancestrySection = document.getElementById('cow-family-tree');
      if (ancestrySection) {
        ancestrySection.scrollIntoView({ behavior: 'smooth' });
      }
    };
    if (this.props.cow.ancestors) {
      if (this.props.cow.ancestors.sire || this.props.cow.ancestors.dam) {
        return (
          <div className="ancestry-link" onClick={scrollToAncestry} >
            View full ancestry
          </div>
        );
      }
    }
    return null;
  }
  renderDam () {
    if (this.props.cow.ancestors && this.props.cow.ancestors.dam) {
      return (
        <div className='parent'>
          <span className="title">Dam: </span>
          {this.props.cow.ancestors.dam.name}
        </div>
      );
    }
    return null;
  }
  renderSire () {
    if (this.props.cow.ancestors && this.props.cow.ancestors.sire) {
      return (
        <div className='parent'>
          <span className="title">Sire: </span>
          {this.props.cow.ancestors.sire.name}
        </div>
      );
    }
    return null;
  }
  renderAncestors () {
    if (this.props.cow.ancestors &&
      this.props.cow.ancestors.sire && this.props.cow.ancestors.dam) {
      return (
        <div className="ancestors">
          { this.renderSire() }
          { this.renderDam() }
          { this.renderAncestryLink() }
        </div>
      );
    }
    return null;
  }
  render () {
    return (
      <article id='qr-additional-data-section'>
        <h2 className='additional-data-title'>
          Authentication
          <Link to={ `${getGBIDomain()}/about/authentication` }>
            <InfoIconThick />
          </Link>
          <div id="qr-additional-data-section-scroll-point"></div>
        </h2>
        <div className='bb-page-wrapper additional-data-content'>
          { this.renderAuthenticationSection() }
          { this.renderAncestors() }
          <KeyFacts
            documents={ this.props.cow ? this.props.cow.documents : null } />
        </div>
      </article>
    );
  }
}

QRAuthentication.propTypes = {
  cow: PropTypes.object.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

export default withRouter(QRAuthentication);
