import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  getAnimalsObj,
  getFetchingAnimalsObj } from '../reducer';
import { fetchAnimal } from '../actions';
import PropTypes from 'prop-types';
import ScoreCard from './ScoreCard';
import { Documents } from '../../cows/components/Documents';
import './gbi-animal.scss';
import GBIAnimalAPI from '../GBIAnimalAPI';
import { isAuthenticated } from '../../util/jwt';
import QRLandingPage from './QRLandingPage';
import LoadingPage from '../../util/components/LoadingPage';
import NotFound from '../../util/components/NotFound';

const mapStateToProps = state => ({
  animals: getAnimalsObj(state),
  fetchingAnimals: getFetchingAnimalsObj(state)
});

const mapDispatchToProps = dispatch => ({
  fetch: id => dispatch(fetchAnimal(id))
});

class GBIAnimal extends Component {
  constructor (props) {
    super(props);
    this.id = this.props.match.params.bbID;
    GBIAnimalAPI.incrementAnimalView(this.id);
  }

  componentDidMount () {
    if (!this.props.animals[this.id]) {
      this.props.fetch(this.id);
    }
  }

  render () {
    const lockedMessage = `This animal cannot be displayed publicly`;
    const notFoundMessage =
      `The URL may be misspelled or else the animal page no longer exists`;
    if (this.props.animals[this.id]) {
      const animal = this.props.animals[this.id];
      if (!animal.liveOnGBI && !isAuthenticated()) {
        return (
          <NotFound message={ lockedMessage } testId="gbi-locked-msg" />
        );
      }
      return (
        <article id='gbi-animal'>
          <Route path={ this.props.match.url + '/documents' }
            render={
              props =>
                <Documents { ...props}
                  cowId={this.id}
                  selectCow={() => {} }
                  cow={ animal } />
            } />
          <Route path={ this.props.match.url + '/scorecard/:attribute' }
            render={
              props =>
                <ScoreCard { ...props } animal={ animal } />
            } />
          <Route
            path={ this.props.match.url }
            render={
              props =>
                <QRLandingPage { ...props } animal={ animal } />
            } />
        </article>
      );
    } else if (this.props.fetchingAnimals[this.id]) {
      return <LoadingPage />;
    }
    return <NotFound message={ notFoundMessage } />;
  }
}

GBIAnimal.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.shape({
      bbID: PropTypes.string.isRequired
    })
  }),
  animals: PropTypes.object.isRequired,
  fetchingAnimals: PropTypes.object.isRequired,
  fetch: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(GBIAnimal);
