import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CowVideoSection from '../../cows/video/components/CowVideoSection';
import CowDataSection from '../../cows/components/CowDataSection';
import FamilyTree from '../../cows/components/FamilyTree';
import QRAuthentication from './QRAuthentication';
import QRVennConcept from './QRVennConcept';
import QRTop from './QRTop';
import QRCarbonHoofprint from './QRCarbonHoofprint';
import QREditAnimalButton from './QREditAnimalButton';
import QRMarbling from './QRMarbling';
import './qr-landing-page.scss';
import { Helmet } from 'react-helmet';
import { getGradeFromScore } from '../../admin/scoreDefinitions/util';
import VennDiagramDefs from '../../util/figures/VennDiagramDefs';
import QRGradeScaleSection from './QRGradeScaleSection';

const QRLandingPage = ({ animal, match, location }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);
  let video = null;
  if (animal.video || animal.orgVideo) {
    const props = {
      video: animal.video || animal.orgVideo,
      dob: animal.dob,
      name: animal.name,
      title: 'Come to our Farm'
    };
    if (animal.breed === 'Aberdeen Angus' &&
    animal.environment.pastureVsGrain.score >= 2 &&
    animal.eating.breed.score >= 3) {
      props.subTitle = 'Pedigree Angus Grass-Fed Suckler Beef';
    }
    video = <CowVideoSection { ...props } />;
  }
  let familyTree = null;
  if (animal.ancestors && Object.values(animal.ancestors)) {
    familyTree = <FamilyTree cow={ animal} />;
  }
  const grade = getGradeFromScore(animal.beefQualityIndex);
  const meta = 'See how GoodBeef Index grades your beef. View scoring ' +
    'on environmental factors, eating factors and production factors. ' +
    'See where you beef came from and more.';
  return (
    <div id="qr-landing-page">
      <VennDiagramDefs />
      <Helmet>
        <title>
          { `GoodBeef Index grade for ${animal.name || animal.tag}: ${grade}`}
        </title>
        <meta name="description" content={ meta } />
        <link rel="canonical"
          href={ `${window.location.origin}/${location.pathname}`} />
      </Helmet>
      <QREditAnimalButton animalId={ animal ? animal.id : null }
        orgId={ animal ? animal.orgId : null } />
      <QRTop animal={ animal } match={ match } />
      <QRVennConcept animal={ animal } />
      <CowDataSection match={ match } cow={ animal } />
      <QRGradeScaleSection score={ animal.beefQualityIndex } />
      <QRCarbonHoofprint animal={ animal } />
      <QRMarbling animal={animal} />
      { video }
      <QRAuthentication cow={ animal } />
      { familyTree }
    </div>
  );
};

QRLandingPage.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.shape({
      cowId: PropTypes.string
    })
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  animal: PropTypes.object
};

export default QRLandingPage;
