import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import './gbi-venn.scss';

export const getTriggerAreaTop = rect => {
  let top = rect.top;
  let bottom = rect.bottom;
  const gap = bottom - top;
  const topPadding = gap / 100 * 28;
  top = top + topPadding;
  return top + window.scrollY;
};

export const getTriggerAreaBottom = rect => {
  let top = rect.top;
  let bottom = rect.bottom;
  const gap = bottom - top;
  const bottomPadding = gap / 100 * 22;
  bottom = bottom - bottomPadding;
  return bottom + window.scrollY;
};

/* eslint-disable */
const GBIVennConceptShapes = ({ className, sheildRef, production, eating, environment, total, scoreId, shieldId, setFactor }) => {
  let gbiScore = total || 0;
  let prodScore = production || '';
  let eatScore = eating || '';
  let envScore = environment || '';
  const id = scoreId || 'gbi-circles-concept-score';
  const imageRef = useRef();
  const handleClick = setFactor ? event => {
    const el = imageRef.current;
    const dim = el.getBoundingClientRect();
    const x = event.clientX - dim.left;
    const y = event.clientY - dim.top;
    if (y < (dim.height/2)) { // top half
      if (x < (dim.width/2)) {// production circle
        setFactor("production");
      } else {
        setFactor("eating");
      }
    } else { // bottom half
      const opposite = y - (dim.height / 2);
      if (x < (dim.width / 2)) {
        const adjacent = (dim.width / 2) - x;
        const angle = Math.atan(opposite/adjacent);
        if (angle <= 0.523599) { // 30 deg in radians
          setFactor('production')
        } else {
          setFactor('environment');
        }
      } else {
        const adjacent = x - (dim.width / 2);
        const angle = Math.atan(opposite/adjacent);
        if (angle <= 0.523599) { // 30 deg in radians
          setFactor('eating')
        } else {
          setFactor('environment');
        }
      }
    }
  } : () => {};
  const scoreStyle = {
    fill: 'white',
    fontWeight: 600
  };
  const outOfStyle = {
    fontSize: '0.6em',
    fontWeight: 600,
    fill: 'rgba(255,255,255,0.4)'
  }
  return (
    <div className="venn-shape-wrapper">
      <svg 
        onClick={ handleClick }
        className={ className ? className + ' gbi-venn-shape' : 'gbi-venn-shape' }
        ref={ imageRef }
        viewBox="0 0 194 194" >
        <g>
          <use href="#venn-prod-circle" opacity="0.3" filter="url(#venn-circle-blur)" />
          <use className="ring production-ring" href="#venn-prod-circle" fillOpacity="0" stroke="#c92f6d" />
        </g>
        <g>
          <use href="#venn-eat-circle" opacity="0.3" filter="url(#venn-circle-blur)" />
          <use className="ring eating-ring" href="#venn-eat-circle" fillOpacity="0" stroke="#c92f6d" />
        </g>
        <g>
          <use href="#venn-env-circle" opacity="0.3" filter="url(#venn-circle-blur)" />
          <use className="ring environment-ring" href="#venn-env-circle" fillOpacity="0" stroke="#c92f6d" />
        </g>
        <g clipPath="url(#venn-prod-clip)">
          <g clipPath="url(#venn-eat-clip)">
            <g clipPath="url(#venn-env-clip)" 
              id={ shieldId || 'gbi-venn-concept-shield' }
              ref={ sheildRef }>
              <use href="#venn-area" fill="#c92f6d"  />
              <text textAnchor="middle">
                <tspan x="50%" y="50%" dy="-0.2em" style={ scoreStyle } id={ id }>
                  { gbiScore }
                </tspan>
                <tspan x="50%" y="50%" dy="0.75em" style={ outOfStyle }>
                  /120
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
      {/* <div className="factor-titles"> */}
        <div className="factor-title environment">
          <div className="factor">environment</div>
          <div className="factor-score">
            <span className="score">{ envScore }</span>
            <span className="forty">/40</span>
          </div>
        </div>
        <div className="factor-title eating">
          <div className="factor">eating</div>
          <div className="factor-score">
            <span className="score">{ eatScore }</span>
            <span className="forty">/40</span>
          </div>
        </div>
        <div className="factor-title production">
          <div className="factor">production</div>
          <div className="factor-score">
            <span className="score">{ prodScore }</span>
            <span className="forty">/40</span>
          </div>
        </div>
      {/* </div> */}
    </div>
  );
};

GBIVennConceptShapes.propTypes = {
  className: PropTypes.string,
  sheildRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  production: PropTypes.number,
  eating: PropTypes.number,
  environment: PropTypes.number,
  total: PropTypes.number,
  scoreId: PropTypes.string,
  shieldId: PropTypes.string,
  setFactor: PropTypes.func
};

export default GBIVennConceptShapes;
