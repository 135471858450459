import React from 'react';
import GBIButton from '../../util/buttons/components/GBIButton';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ShortArrow from '../../util/icons/components/ShortArrow';
import { getOrgIds, isAdmin, isAuthenticated } from '../../util/jwt';
import './edit-animal-button.scss';

const QREditAnimalButton = ({ orgId, animalId }) => {
  if (!orgId || !animalId) return null;
  let authorised = isAdmin() && isAuthenticated();
  if (isAuthenticated()) {
    const orgIds = getOrgIds() ? getOrgIds() : [];
    orgIds.forEach(id => {
      if (id === orgId) authorised = true;
    });
  }
  if (!authorised) return null;
  return (
    <div className="edit-animal-button-wrapper">
      <Link to={ `/account/animals/${animalId}` } >
        <GBIButton testId="edit-animal-button" label="edit" >
          <ShortArrow />
        </GBIButton>
      </Link>
    </div>
  );
};

QREditAnimalButton.propTypes = {
  orgId: PropTypes.string,
  animalId: PropTypes.string
};

export default QREditAnimalButton;
