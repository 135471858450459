/* eslint-disable max-len */
import React from 'react';
import './carbon-hoofprint-small.scss';

const CarbonHoofprintSmallLogo = () => (
  <svg className="carbon-hoofprint-logo-small" viewBox="0 0 994 285.42">
    <path className="text" d="M414.55,103.66q-10.68,7-25.54,7-14,0-24.39-5.92a39.37,39.37,0,0,1-15.94-16.95q-5.56-11-5.56-26.19t5.56-26.19a39.45,39.45,0,0,1,15.87-16.95q10.32-5.93,24.17-5.92,14.86,0,25.18,6.2a32,32,0,0,1,14.21,18.47l-18,7.65q-2.32-8.51-7.22-12.26t-13.27-3.75q-12.13,0-19,8.51T363.76,61.6q0,15.74,6.63,24.24t18.76,8.51q17.46,0,21.93-16.88L429.41,83Q425.23,96.66,414.55,103.66Z" transform="translate(-3 -5.1)"/>
    <path className="text" d="M514.15,96.66l-1.44,12a20.66,20.66,0,0,1-9.38,2q-13.86,0-16.6-10.82a19.67,19.67,0,0,1-9.8,8.08,41,41,0,0,1-15.73,2.74q-10.53,0-16.45-4.76t-5.92-13.71q0-17.59,28.14-22.8l17.32-3.32V62.61q0-5.78-3.25-9.16t-9-3.39q-6.64,0-10.82,2.88t-5.77,9.38L439.7,55.68A24,24,0,0,1,450.09,41q8.07-5.33,21.06-5.33,15.45,0,24.1,6.71t8.66,19.84V91.76a5.76,5.76,0,0,0,1.29,4.18,5.51,5.51,0,0,0,4,1.3A16.9,16.9,0,0,0,514.15,96.66ZM479,94.14q5.26-3.11,5.27-8.44V78.19l-15.44,3.47a18.13,18.13,0,0,0-7.29,3.1,6.6,6.6,0,0,0-2.38,5.41,6.49,6.49,0,0,0,2.16,5.27,9.22,9.22,0,0,0,6.06,1.8A22.49,22.49,0,0,0,479,94.14Z" transform="translate(-3 -5.1)"/>
    <path className="text" d="M572.87,37.21l-2.46,17.32c-2.21-1.44-5.24-2.17-9.09-2.17a16.61,16.61,0,0,0-12.19,5.13Q544,62.61,544,71.84v37.38H524.24V37.07h17.17l1.3,14.14q5.91-15.58,22.36-15.58A15.86,15.86,0,0,1,572.87,37.21Z" transform="translate(-3 -5.1)"/>
    <path className="text" d="M648.19,45.58q8.22,10,8.22,27.71t-8.22,27.48q-8.22,9.9-22.66,9.89a25.7,25.7,0,0,1-14.79-4.26,26.37,26.37,0,0,1-9.45-11.47l-.72,14.29H583.11V5.47h19.77V48.9A25,25,0,0,1,612,39.16a25.4,25.4,0,0,1,13.56-3.53Q640,35.63,648.19,45.58Zm-16,44.16q4.47-5.77,4.47-16.6t-4.47-16.59q-4.47-5.77-12.55-5.77a14.86,14.86,0,0,0-11.69,5q-4.47,5.06-5,14.14v6.35q.57,9.24,5,14.21a15,15,0,0,0,11.69,5Q627.69,95.51,632.17,89.74Z" transform="translate(-3 -5.1)"/>
    <path className="text" d="M731.88,45.58q9.74,10,9.74,27.56t-9.74,27.56q-9.75,10-27.2,10t-27.2-10q-9.74-9.95-9.74-27.56t9.74-27.56q9.74-10,27.2-9.95T731.88,45.58ZM691.91,56q-4.41,5.72-4.4,17.1t4.4,17.1q4.4,5.7,12.77,5.7t12.77-5.7q4.39-5.7,4.4-17.1T717.45,56q-4.41-5.68-12.77-5.7T691.91,56Z" transform="translate(-3 -5.1)"/>
    <path className="text" d="M817.9,42.41q7.15,6.78,7.14,19.48v47.33H805.28V67.66q0-16.45-12.84-16.45a15.92,15.92,0,0,0-11.77,4.91q-4.83,4.91-4.83,15v38.1H756.08V37.07h17.74l.58,12.84a25,25,0,0,1,10-10.68,29.38,29.38,0,0,1,14.72-3.6Q810.76,35.63,817.9,42.41Z" transform="translate(-3 -5.1)"/>
    <path className="hoof-leaf" d="M429.7,146.14v95.23H409.79V201.25h-42v40.12H347.88V146.14H367.8v39.1h42v-39.1Z" transform="translate(-3 -5.1)"/>
    <path className="hoof-leaf" d="M510.37,177.74q9.75,9.94,9.74,27.56t-9.74,27.55q-9.74,10-27.19,10t-27.21-10q-9.74-9.94-9.73-27.55T456,177.74q9.75-10,27.21-10T510.37,177.74Zm-40,10.46q-4.39,5.7-4.4,17.1t4.4,17.1q4.41,5.68,12.78,5.69t12.76-5.69q4.41-5.7,4.4-17.1t-4.4-17.1q-4.4-5.7-12.76-5.7T470.4,188.2Z" transform="translate(-3 -5.1)"/>
    <path className="hoof-leaf" d="M595,177.74q9.75,9.94,9.74,27.56T595,232.85q-9.74,10-27.19,10t-27.21-10q-9.74-9.94-9.73-27.55t9.73-27.56q9.75-10,27.21-10T595,177.74ZM555,188.2q-4.39,5.7-4.4,17.1t4.4,17.1q4.41,5.68,12.78,5.69t12.76-5.69q4.41-5.7,4.4-17.1t-4.4-17.1q-4.39-5.7-12.76-5.7T555,188.2Z" transform="translate(-3 -5.1)"/>
    <path className="hoof-leaf" d="M643.28,169.22h22.8v14.72H644.15v57.43H624.38V183.94H611.83V169.22h12.55v-8.65q0-11.83,7.36-18.11t19.91-6.28a35.52,35.52,0,0,1,8.73,1,17.81,17.81,0,0,1,6.28,2.74l-2.74,13.13a14.81,14.81,0,0,0-8.23-2.16q-12.41,0-12.41,11.11Z" transform="translate(-3 -5.1)"/>
    <path className="hoof-leaf" d="M741.16,177.66q8.24,9.89,8.23,27.49t-8.23,27.7q-8.22,10-22.65,10A25.49,25.49,0,0,1,705,239.28a25,25,0,0,1-9.09-9.74v42.13H676.09V169.22h17.46l.72,14.29A26.43,26.43,0,0,1,703.72,172a25.68,25.68,0,0,1,14.79-4.26Q732.94,167.78,741.16,177.66Zm-16,44.23q4.47-5.77,4.47-16.59t-4.47-16.6q-4.47-5.76-12.55-5.77a15,15,0,0,0-11.69,5q-4.47,5-5,14.21v6.35q.57,9.09,5,14.14a14.85,14.85,0,0,0,11.69,5Q720.67,227.66,725.15,221.89Z" transform="translate(-3 -5.1)"/>
    <path className="hoof-leaf" d="M812.77,169.37l-2.46,17.31q-3.31-2.16-9.08-2.16a16.67,16.67,0,0,0-12.2,5.12q-5.13,5.12-5.12,14.36v37.37H764.14V169.22h17.17l1.3,14.14q5.92-15.59,22.37-15.58C808.35,167.78,810.94,168.31,812.77,169.37Z" transform="translate(-3 -5.1)"/>
    <path className="hoof-leaf" d="M820.7,148.3q0-10.68,12.26-10.68t12.27,10.68q0,10.53-12.27,10.53T820.7,148.3Zm22.08,93.07H823V169.22h19.77Z" transform="translate(-3 -5.1)"/>
    <path className="hoof-leaf" d="M922.72,174.56q7.14,6.78,7.14,19.48v47.33H910.09V199.81q0-16.44-12.83-16.45a15.89,15.89,0,0,0-11.77,4.91q-4.83,4.9-4.83,15v38.09H860.89V169.22h17.75l.58,12.84a24.88,24.88,0,0,1,9.95-10.67,29.22,29.22,0,0,1,14.72-3.61Q915.58,167.78,922.72,174.56Z" transform="translate(-3 -5.1)"/>
    <path className="hoof-leaf" d="M997,236.9a26,26,0,0,1-8.73,4.33,38.32,38.32,0,0,1-11,1.58q-24.11,0-24.1-21.79V183.94H940V169.22h13.12V152.63l19.78-5.49v22.08h23.37v14.72H972.91v33.91q0,10,10,10a16.68,16.68,0,0,0,11.11-4Z" transform="translate(-3 -5.1)"/>
    <path className="hoof-leaf" d="M129.91,5.1S63.5,14.66,23.13,90.73C-10,153.17.52,200.16,34.64,225.1a62,62,0,0,0,31,11.35c-9-33.65-17-82.74-6.49-130.35.09,94.91,44,184.42,44,184.42s9.35-7.47,1.57-29a111.16,111.16,0,0,1-6.47-29.94c12.21-5.11,22.53-13.89,28.28-25.87,17.54-36.59,4.23-67.84-11.36-106.18C93.88,47.34,129.91,5.1,129.91,5.1Z" transform="translate(-3 -5.1)"/>
    <path className="hoof-leaf" d="M160.49,5.1s66.41,9.56,106.78,85.63c33.13,62.44,22.61,109.43-11.52,134.37a62,62,0,0,1-30.94,11.35c8.95-33.65,17-82.74,6.49-130.35-.09,94.91-44,184.42-44,184.42s-9.34-7.47-1.56-29a111.55,111.55,0,0,0,6.47-29.94c-12.21-5.11-22.53-13.89-28.28-25.87-17.54-36.59-4.23-67.84,11.36-106.18C196.51,47.34,160.49,5.1,160.49,5.1Z" transform="translate(-3 -5.1)"/>
  </svg>
);

export default CarbonHoofprintSmallLogo;
